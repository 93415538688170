import React from 'react';
import { Link as LinkRR, useLocation } from 'react-router-dom';
import LayoutType from 'src/shared/utils/layout_type';
import Menu from './Menu';
import MenuMobile from './MenuMobile';
import MenuAccount from './MenuAccount';
import Logo from 'images/logo.svg';
import EmsAdminLogo from 'src/shared/icons/EmsAdminLogo';

import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarMenuToggle,
} from "@nextui-org/react";

import classNames from "classnames";

const Header = ({layoutType}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const reloadFrontLink = layoutType !== LayoutType.FRONT_SITE;
  const current_location = useLocation();

  const mainLayoutHeader = classNames("main-layout-header", {
    "home-page-header": layoutType === LayoutType.FRONT_SITE && current_location.pathname === "/",
    "front-site-header": (layoutType === LayoutType.FRONT_SITE && current_location.pathname !== "/") || (![LayoutType.BOM_QUOTE, LayoutType.FRONT_SITE].includes(layoutType)),
    "bom-quote-header": layoutType === LayoutType.BOM_QUOTE,
  })

  const klassContainer = classNames({
    'container': ![LayoutType.BOM_QUOTE, LayoutType.BOM_COMPARE].includes(layoutType),
  })

  return (
    <div className={mainLayoutHeader}>
      <div className={klassContainer}>
        <Navbar
          maxWidth="full"
          onMenuOpenChange={setIsMenuOpen}
          className="backdrop-filter-none bg-transparent py-3.5"
          classNames={{
            wrapper: "",
          }}
        >
          <NavbarContent>
            <NavbarMenuToggle
              aria-label={isMenuOpen ? "Close menu" : "Open menu"}
              className="sm:hidden"
            />
            <NavbarBrand>
              <LinkRR to="/" reloadDocument={reloadFrontLink}>
                <EmsAdminLogo 
                  width={217.92}
                  height={27}
                  strokeWidth={0}
                  color={layoutType === LayoutType.BOM_QUOTE ? "#293538" : "#ffffff"}
                  fill={layoutType === LayoutType.BOM_QUOTE ? "#293538" : "#ffffff"}
                  />
              </LinkRR>
            </NavbarBrand>
          </NavbarContent>


          <NavbarContent justify="end" className="grow-0 gap-7">
            <Menu layoutType={layoutType} />
            <MenuAccount layoutType={layoutType} />
          </NavbarContent>
          <MenuMobile layoutType={layoutType} />
        </Navbar>
      </div>
    </div>
  )
}

export default Header;